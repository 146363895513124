import React from 'react'
import './main.css'

const ExpertiesSection = () => {
  return (
    <>
        <div className="container-fluid expertise-bg">
          <div className="row justify-content-evenly">
            <div className="col-12 col-md-10">
              <div className="row justify-content-evenly py-5">
                <div className="col-lg-4 expertise-card">
                    <div className='py-2'>Digital Strategy & Consulting</div>
                    <div className='card-line'></div>
                    <p>We offer comprehensive strategies aimed at enhancing all aspects of our organization by integrating cutting-edge digital technologies and elevating the overall customer experience.</p>
                  </div>
                <div className="col-lg-4 mt-5 mt-md-0 expertise-card">
                    <div className='py-2'>Solutions by leveraging cutting-edge technologies</div>
                    <div className='card-line'></div>
                    <p>We create a strategic framework to align with organizational objectives, enabling personalized experiences and optimizing customer journeys.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>

    </>
  )
}

export default ExpertiesSection
