import { NavLink } from "react-router-dom";
import "./footer.css";
import { animateScroll as scroll } from 'react-scroll';


const Footer = () => {
  
  const toggleHome=()=>{
    scroll.scrollToTop();
   }

  return (
    <div className="footer-bg foot-margin">
    <div className="container-fluid text-light footer-bg">
      <div className="row py-4 py-md-0">
          <div className="col-12 col-md-5 section-separator p-4 p-lg-5">
            <h2 className="head-color pb-3"><b>Afucent</b></h2>
            <p>We are a technology-driven company powered by a team of innovative and passionate individuals</p>
            <a href="/">
             <i className="fa-brands fa-facebook-square"></i>
            </a>
            <a href="/">
              <i className="fa-brands fa-instagram-square"></i>
            </a>
            <a href="https://www.linkedin.com/company/afucent-technologies/">
              <i className="fa-brands fa-linkedin"></i>
            </a>

          </div>
          
          <div className="col-4 col-md-3 section-separator">
            <ul className="list-unstyled p-2 p-lg-5">
              <li className="head-color"><h4><b>Quick&nbsp;Links</b></h4></li>
              <li>&nbsp;</li>
              <li><NavLink className="foot-link" to="/" onClick={toggleHome} exact='true' >Home</NavLink></li>
              <li><NavLink className="foot-link" to="/service" onClick={toggleHome} exact='true' >Services</NavLink></li>
              <li><NavLink className="foot-link" to="/expertise" onClick={toggleHome} exact='true' >Expertise</NavLink></li>
              <li><NavLink className="foot-link" to="/about" onClick={toggleHome} exact='true' >About Us</NavLink></li>
              <li><NavLink className="foot-link" to="/careers" onClick={toggleHome} exact='true' >Careers</NavLink></li>
              <li><NavLink className="foot-link" to="/contact" onClick={toggleHome} exact='true' >Contact Us</NavLink></li>
            </ul>
          </div>
        
          <div className="col-8 col-md-4">
            <ul className="list-unstyled p-2 p-lg-5">
              <li className="head-color"><h4><b>Connect With Us</b></h4></li>
              <li>&nbsp;</li>
              <li>4th Floor, New Janpath Complex 9 Ashok Marg, Hazratganj, Lucknow 226001</li>
              <li className="py-3"><i class="fa fa-envelope" aria-hidden="true"></i> &nbsp; hr@afucent.com</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i> &nbsp; +91-9321800758</li>
            </ul>
          </div>
          
      </div>
      <div className="row p-1 border-top border-secondary text-light tnc-bg">
        <div className="col-md-1"></div>
        <div className="col-md-2 text-center">Terms and conditions</div>
        <div className="col-md-5"></div>
        <div className="col-md-3 text-center">©2023 Afucent | All right reserved</div>
        <div className="col-md-1"></div>
      </div>

    </div>
</div>
    // <div className="footer">
    //   <div className="top">
    //     <div className="t">
    //       <a href="/">
    //         <i className="fa-brands fa-facebook-square"></i>
    //       </a>
    //       <a href="/">
    //         <i className="fa-brands fa-instagram-square"></i>
    //       </a>
    //       <a href="https://www.linkedin.com/company/afucent-technologies/">
    //         <i className="fa-brands fa-linkedin"></i>
    //       </a>
    //       <a href="/">
    //         <i className="fa-brands fa-twitter-square"></i>
    //       </a>
    //     </div>
    //   </div>
    //   <div className="bottom">
    //     <div>
    //       <h4>Home</h4>
       
    //     </div>
    //     <div>
    //       <h4>Services</h4>
          
    //     </div>
    //     <div>
    //       <h4>Expertise</h4>
          
    //   </div>
    //     <div>
    //       <h4>About Us</h4>
          
    //     </div>
    //     <div>
    //       <h4>Careers</h4>
          
    //   </div>
    // </div>
    // <div className="end"><h5>© Copyright 2023. All rights reserved.</h5></div></div>
  );
};
export default Footer;
