import React from "react";
import {InfoContainer,InfoWrapper,InfoRow,Column1,Column2, TextWrapper,TopLine,Heading,Subtitle, ImgWrap,Img} from "./InfoElements";

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description,p1,p2,p3,p4, buttonLabel, buttonLink, img,alt, primary, dark, dark2}) => {
  return (
    <>
      <InfoContainer  lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{p1}</Subtitle>
                <Subtitle darkText={darkText}>{p2}</Subtitle>
                <Subtitle darkText={darkText}>{p3}</Subtitle>
                
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
       
    </>
  );
};

export default InfoSection;
