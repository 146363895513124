export const serviceObjOne={
  id:'implementation',
  lightBg: true,
  heading: 'Our mode of engagement',
  lightText: false,
  lightTextDesc: false,
  topLine: 'Services',
  headline: 'Implementation',
  // description: 'Development from scratch ',
  
  p1:"Our team comprises a group of highly skilled and experienced developers and testers, each possessing a wealth of expertise in various areas of software development. With their combined knowledge and proficiency, we are well-equipped to take on the challenge of successfully implementing a wide range of applications tailored to our clients' unique requirements.",
  p2:"Our Agile and iterative approach not only facilitates rapid delivery but also empowers us to maintain constant communication and collaboration with our clients. We understand that the needs and priorities of our customers may evolve during the development process. Therefore, we ensure that the final solution aligns precisely with our clients' expectations, enhancing customer satisfaction and fostering long-term partnerships.",
  // p3:"We also align with their methodologies, accommodating their preferences and adapting our processes accordingly.",
  
  buttonLabel: 'learn more',
  imgStart: false,
  img: require('../../../images/service1.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const serviceObjTwo={
  id:'consulting',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Services',
  headline: 'Consulting',
  // description:'- Audit & Assessment of customer’s existing system and provide strategy for better solution',
  
  p1:"We provide comprehensive application audits for our valued customers. Through these audits, our goal is to assist them in recognizing and addressing any existing challenges they may be facing within their current applications.",
  p2:"During the application audit process, we conduct thorough evaluations of the codebase, architecture, security measures, performance metrics, and user experience. By doing so, we can pinpoint any potential bottlenecks or vulnerabilities that might hinder the application's performance or compromise its security.",
  p3:"In addition, we offer tailor-made solutions to meet our customers' unique requirements. Our team collaborates closely with each client to understand their specific needs and expectations, enabling us to develop customized action plans for every individual client.",
  
  buttonLabel: 'learn more',
  imgStart: true,
  img: require('../../../images/service2.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const serviceObjThree={
  id:'managed',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Services',
  headline: 'Managed Services',
  // description: '- Provide maintenance support to existing implementation of the mentioned technologies',
  
  p1:"We deliver comprehensive maintenance and support services for a wide range of technologies.",
  p2:"Our maintenance and support services are designed to provide continuous assistance to our valued customers. Our team of skilled professionals is always ready to handle updates, bug fixes, and improvements to keep the applications running at their best.",
  p3:"In addition, we offer Staff Augmentation services that come with a pool of experienced and highly skilled resources. These professionals seamlessly integrate with our customers' or technology partner's ongoing implementations, becoming an extension of their teams.",
  p4:"Our Staff Augmentation services allow our customers to focus on their core business activities while having the flexibility to manage their projects effectively. Whether they need additional developers, designers, or any other technical expertise, we can quickly provide the right resources to meet their unique requirements.",
  
  buttonLabel: 'learn more',
  imgStart: false,
  img: require('../../../images/service3.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}