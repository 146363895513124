// About
export const aboutObjOne={
  id:'about1',
  lightBg: true,
  // heading: 'Transforming Industries through Technology',

  lightText: false,
  lightTextDesc: false,
  // topLine: 'Digital Transformation',
  headline: 'Transforming Industries through Technology',

  p1:"Our goal is to empower organizations in transforming their business structures through the utilization of advanced tools and scalable solutions.",
  p2:"Our team is comprised of experts with diverse levels of expertise, who work collaboratively to deliver customized solutions that strike the right balance between technology and business needs.",

  // buttonLabel: 'learn more',
  imgStart: false,
  img: require('../../../images/about1.jpg'),
  img2: require('../../../images/about1.jpg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const aboutObjTwo={
  id:'about2',
  lightBg: true,
  // heading: 'Empowering Digital Experience',

  lightText: false,
  lightTextDesc: false,
  // topLine: 'Digital Transformation',
  headline: 'Empowering Digital Experience',

  p1:"At Afucent, we understand the importance of continuous innovation in today's digital landscape.",
  p2:"By staying informed about the latest digital trends and technologies, we seamlessly integrate them into our clients' business strategies, ensuring they stay ahead of the competition.",
  
  // buttonLabel: 'learn more',
  imgStart: true,
  img: require('../../../images/about2.jpg'),
  img2: require('../../../images/about2.jpg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const aboutObjThree={
  id:'about3',
  lightBg: true,
  // heading: 'About Founder and CEO',

  lightText: false,
  lightTextDesc: false,
  // topLine: 'Digital Transformation',
  headline: 'About Founder and CEO',

  p1:"Meraj Siddiqui, a visionary with over 19 years of experience in the IT industry, is a leading expert in MarTech, digital strategy, and CXM consulting. As the Founder and CEO of Afucent Technologies, he drives the company's vision, strategy, value, and growth.",
  p2:"With a rich background working with multinational corporations and a specialization in Digital Experience and Digital Transformation solutions, Meraj offers comprehensive solutions to empower brands in transforming their businesses.",
  // p3:"With a passion for driving success, Meraj helps businesses unlock their full potential and achieve remarkable results.",
  // p4:"Through his strategic guidance and deep industry expertise, he has become a trusted partner for brands seeking to optimize their digital strategies.",
  
  // buttonLabel: 'learn more',
  imgStart: false,
  img: require('../../../images/mblProfile.png'),
  img2: require('../../../images/deskProfile.png'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}