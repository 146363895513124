import React, { useState } from "react";
import './main.css'
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Banner from "../components/SectionBanner/Banner";
import aboutImg from "../images/aboutBanner.png";
import aboutImg2 from "../images/aboutBanner2.png";
import AboutSection from "../components/AboutUS/AboutSection";

function About() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-gray">
    <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Banner cName="banner-mid" bannerImg={aboutImg} bannerImg2={aboutImg2} title=" We are a technology-driven company powered by an innovative team" btnClass="hide" />
      
      <div className="comp-padding">
        <div className="container-fluid pt-5 pt-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 main-text pb-md-5">
              Driven by passion and innovation, we foster a culture of respect, collaboration, and excellence to deliver top-notch solutions.
            </div>
          </div>
        </div>
      </div>
      
      <AboutSection/>
     
      <Footer/>
    </div>
  )
}

  export default About;