import "./aboutSection.css";
import InfoSection from "./InfoSection";
import { aboutObjOne, aboutObjTwo, aboutObjThree } from "./InfoSection/Data";
import Team from "./Team";
import Values from "./Values";
// import TeamSlider from "./TeamSlider";

function AboutSection() {
  return (
    <div className="aboutSection-padding">
      <InfoSection  {...aboutObjOne}/> 
      <InfoSection  {...aboutObjTwo}/> 
      
      <Values />
      
      <InfoSection  {...aboutObjThree}/> 
      
      <Team />

      {/* <h1 className="team-head text-center">The Driving Force Behind Our Success</h1> */}
      {/* <TeamSlider /> */}
    </div>
  );
}
export default AboutSection;
