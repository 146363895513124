import { useState } from "react";
import "./CareerStyles.css";
import JD from "./JD";
import { JDobjOne, JDobjTwo, JDobjThree, JDobjFour, JDobjFive, JDobjSix, JDobjSeven } from "./JDdata"; // Add new JD objects
import InfoSection from "./InfoSection";
import { careerObjOne } from "./InfoSection/Data";

const Careers = () => {
  const [jd1, setJD1] = useState(false);
  const [jd2, setJD2] = useState(false);
  const [jd3, setJD3] = useState(false);
  const [jd4, setJD4] = useState(false);
  const [jd5, setJD5] = useState(false);
  const [jd6, setJD6] = useState(false);
  const [jd7, setJD7] = useState(false);

  const toggleJD = (i) => {
    setJD1(false);
    setJD2(false);
    setJD3(false);
    setJD4(false);
    setJD5(false);
    setJD6(false);
    setJD7(false);
    
    switch (i) {
      case 1:
        setJD1(!jd1);
        break;
      case 2:
        setJD2(!jd2);
        break;
      case 3:
        setJD3(!jd3);
        break;
      case 4:
        setJD4(!jd4);
        break;
      case 5:
        setJD5(!jd5);
        break;
      case 6:
        setJD6(!jd6);
        break;
      case 7:
        setJD7(!jd7);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <InfoSection {...careerObjOne} /> 

      <div className="container">
        <div className="row justify-content-evenly p-4 career-bg rounded">

          <div className="value-font text-center pt-4">Explore Opportunities</div>
          <div className="career-card col-lg-5 m-4 m-md-2 m-lg-4">
            <h4>Interns</h4>
            <div className="card-line"></div>
            <p>N/A</p>
            {/* <p onClick={() => toggleJD(1)}>MBA Intern - Digital Marketing</p> */}
            {/* <p onClick={() => toggleJD(2)}>PHP Developer - Moodle, Drupal, WordPress</p> */}
          </div>
          <div className="career-card col-lg-5 m-4 m-md-2 m-lg-4">
            <h4>Professionals</h4>
            <div className="card-line"></div>
            <p onClick={() => toggleJD(7)}>Technical Project Manager (Software Development)</p>
            {/* <p onClick={() => toggleJD(4)}>UX/UI Junior Designer</p> */}
            {/* <p onClick={() => toggleJD(5)}>Quality Assurance Engineer</p> */}
            {/* <p onClick={() => toggleJD(6)}>Digital Analyst</p> */}
          </div>

          <>
            {jd1 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjOne} />
              </div>
            }
            {jd2 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjTwo} />
              </div>
            }
            {jd3 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjThree} />
              </div>
            }
            {jd4 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjFour} />
              </div>
            }
            {jd5 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjFive} />
              </div>
            }
            {jd6 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjSix} />
              </div>
            }
            {jd7 && 
              <div className="bg-light card col-md-11 p-3 p-md-5 mb-0 mb-md-5 rounded text-dark">
                <JD {...JDobjSeven} />
              </div>
            }
          </>

        </div>
      </div>
    </>
  );
};

export default Careers;
