export const expertiseObjOne={
  id:'experience',
  lightBg: true,
  heading: 'Our Specialization',
  lightText: false,
  lightTextDesc: false,
  topLine: 'Expertise',
  headline: 'Digital Experience',
  // description: 'Development from scratch ',
  
  p1:"We have strong vision for the Digital Experience Practice driven by a customer-centric approach, creating personalized and engaging digital experiences tailored to their needs.",
  p2:"Our UX/UI expertise crafts intuitive, user-friendly interfaces through usability testing, iterative design processes, and maintaining consistency across digital touchpoints.",
  p3:"Our practice revolves around informed decision-making driven by data and analytics, helping in optimizing digital experiences for our customers, ensuring their satisfaction and success.",
  p4:"We embrace the latest trends and technologies in the digital experience space, leveraging AI-based solutions to deliver cutting-edge experiences for our valued customers.",
  
  buttonLabel: 'learn more',
  // buttonLink: '/expertise/digital_experience',
  imgStart: false,
  img: require('../../../images/expertise1.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const expertiseObjTwo={
  id:'marketing',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Expertise',
  headline: 'Marketing Technologies',
  // description:'- Audit & Assessment of customer’s existing system and provide strategy for better solution',

  p1:"We have a dedicated team of experts who excel in delivering cutting-edge solutions for advanced marketing technologies.",
  p2:"One of the key strengths of our Marketing Technology Practice lies in our ability to provide customized marketing automation solutions. We work closely with our clients to thoroughly comprehend their specific needs and pain points and design tailored automation solutions that align perfectly with their business strategies.",
  p3:"Our team continuously evaluates emerging tools, platforms, and methodologies to identify opportunities for innovation and optimization. By staying updated with the ever-changing marketing tech landscape, we ensure that our clients benefit from the most effective and relevant solutions available.",
  
  buttonLabel: 'learn more',
  // buttonLink: '/expertise/marketing_technologies',
  imgStart: true,
  img: require('../../../images/expertise2.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}

export const expertiseObjThree={
  id:'digital',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Expertise',
  headline: 'Digital Transformation',
  // description: '- Provide maintenance support to existing implementation of the mentioned technologies',
  
  p1:'We recognize the dynamic nature of the modern business landscape and understand that to stay competitive, we must constantly evolve and leverage innovative technologies.',
  p2:"We actively immerse ourselves in the process, investing in cutting-edge technologies and staying at the forefront of industry trends.",
  p3:"At the core of our digital transformation strategy is the seamless integration of innovative technologies. From AI and machine learning to advanced analytics and automation, we explore and implement solutions that bring immense value to our operations and empower our teams to deliver superior results.",
  
  buttonLabel: 'learn more',
  // buttonLink: '/expertise/digital_transformation',
  imgStart: false,
  img: require('../../../images/expertise3.jpeg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}
