import React from 'react'
import './main.css'

const ExpertiesSection = () => {
  return (
    <>
     <div className="container-fluid expertise-bg">
          <div className="row justify-content-evenly">
            <div className="col-12 col-md-10">
              <div className="row justify-content-evenly py-5">
                <div className="col-lg-4 expertise-card">
                    <div className='py-2'>Content Management System</div>
                    <div className='card-line'></div>
                    <p>As experts in diverse Content Management Systems (CMS), we are well-versed in traditional CMS, headless CMS, and CMS as a SAAS (Cloud-based model). Our deep understanding of these systems allows us to tailor the perfect CMS solution to meet your unique needs and propel your digital presence forward.</p>
                  </div>
                <div className="col-lg-4 mt-5 mt-md-0 expertise-card">
                    <div className='py-2'>Web & Mobile Application</div>
                    <div className='card-line'></div>
                    <p>We specializes in cross-platform web and mobile app development, enabling you to create applications that seamlessly run across multiple platforms, including web browsers, iOS, and Android. By leveraging a single codebase, we streamline the development process and deliver efficient, cost-effective solutions for your digital needs.</p>
                  </div>
                <div className="col-lg-4 mt-5 mt-md-0 expertise-card">
                    <div className='py-2'>E-commerce</div>
                    <div className='card-line'></div>
                    <p>We leverage the power of popular E-commerce frameworks such as Magento, Shopify, and WooCommerce to deliver comprehensive development solutions for businesses of all sizes and requirements.</p>
                </div>
              </div>
            </div>
          </div>
        </div> 
    </>
  )
}

export default ExpertiesSection
