import React, { Fragment, useState } from "react";
import ExpertiseDetails from "./ExpertiseDetails";
import "./Expertise.css";
import { LinkButton } from "../../LinkButton";

import { animateScroll as scroll } from 'react-scroll';

const Expertise = () => {
  const [detail] = useState(ExpertiseDetails);
  
  const toggleHome=()=>{
    scroll.scrollToTop();
   }

  return (
    <Fragment>
      <section className="sh">
        <div className="sh-heading">
          <span>Our Expertise</span>
          <h2>Seamless Customer Experience with Integrated Technology</h2>
        </div>
        <div className="s-row">
          {detail.map((detail) => {
            return (
              <div className="s-col">
                <div className="s-card ">
                  <div className="thumb-ex">
                    <img src={detail.Image} alt="agent" />
                  </div>
                  <div className="s-info-ex">
                    <h3>{detail.name}</h3>
                    <p>{detail.info}</p>
                    <div className="s-b-ex">
                        <LinkButton to="/expertise" onClick={toggleHome}  exact='true' >Learn more</LinkButton>{" "}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default Expertise;
