import React from 'react'
import './main.css'

const ExpertiesSection = () => {
  return (
    <>
        <div className="container-fluid expertise-bg">
          <div className="row justify-content-evenly">
            <div className="col-12 col-md-10">
              <div className="row justify-content-evenly py-5">
                <div className="col-lg-4 expertise-card">
                    <div className='py-2'>Digital Analytics</div>
                    <div className='card-line'></div>
                    <p>We have an expert team for our digital analytics piece, encompassing comprehensive services such as data collection, implementation, analysis, and insights generation.</p>
                  </div>
                <div className="col-lg-4 mt-5 mt-md-0 expertise-card">
                    <div className='py-2'>Marketing Automation</div>
                    <div className='card-line'></div>
                    <p>We help organizations to effectively engage with prospects, nurture leads, personalize experiences, and measure marketing performance by leveraging advanced marketing automation tools.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>  
    </>
  )
}

export default ExpertiesSection
