import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Banner from "../components/SectionBanner/Banner";
import careerImg from "../images/careerBanner.png";
import careerImg2 from "../images/careerBanner2.png";
import Careers from "../components/Career/Careers";
 
function Career() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <> 
    <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Banner cName="banner-mid" bannerImg={careerImg} bannerImg2={careerImg2} title="Empowering Human Potential for Technological Advancement" btnClass="hide" />
      <div className="bg-gray">

      <div className="comp-padding">
        <div className="container-fluid pt-5 pt-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 main-text pb-md-2">
              No matter where you are in your career, we invite you to join our thriving organization and become part of our growth.
            </div>
          </div>
        </div>
      </div>

        <Careers/>

        <Footer/>
      </div>
    </>
  )
}

  export default Career;