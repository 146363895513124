const ServiceDetails=[
    {
        id:"1",
        Image: require('../../../images/service2.jpeg'),
        name: "Implementation",
    },
    {
        id:"2",
        Image: require('../../../images/service1.jpeg'),
        name: "Consulting"
    },
    {
        id:"3",
        Image: require('../../../images/service3.jpeg'),
        name: "Managed Service",    
    },
]
export default ServiceDetails;

