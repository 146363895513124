import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar";

import Slider from "../components/Home/Carousal/Slider";
import About from "../components/Home/About/About";
import Clients from "../components/Home/Clients/Clients";
import ServicesHome from "../components/Home/ServicesHome/ServicesHome";
import Expertise from "../components/Home/ExpertiseHome/Expertise";
import Connect from "../components/Home/Contact/Contact";

import Footer from "../components/Footer";

// mobile navbar toggle function
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Slider/>
      <div className="bg-gray">
        <About/>
        <Clients/> 
        <ServicesHome/>
        <Expertise/>
      </div>
      <Connect/>
      <Footer/>
    </>
  );
};

export default Home;
