import './App.css';
import {Routes , Route} from 'react-router-dom';
import Home from './routes/index';
import About from './routes/About';
import Service from './routes/Services';
import Expertise from './routes/Expertise';
// import DigitalExperience from './routes/Expertise/DigitalExperience';
// import CMS from './routes/Expertise/DigitalExperience/CMS';
// import MarketingTechnologies from './routes/Expertise/MarketingTechnologies';
// import DigitalTransformation from './routes/Expertise/DigitalTransformation';
import Career from './routes/Career';
import Contact from './routes/Contact';


function App() {
  return (
  
      <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/expertise" element={<Expertise />} />
        {/* <Route path="/expertise/digital_experience" element={<DigitalExperience />} /> */}
        {/* <Route path="/expertise/digital_experience/cms" element={<CMS />} /> */}
        {/* <Route path="/expertise/marketing_technologies" element={<MarketingTechnologies />} /> */}
        {/* <Route path="/expertise/digital_transformation" element={<DigitalTransformation />} /> */}
        <Route path="/careers" element={<Career/>} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
  

  );
}

export default App;
