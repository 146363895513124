export const JDobjOne={
    jd: "MBA Intern - Digital Marketing",
    head:'We are seeking a talented and motivated MBA intern to join our digital marketing team. As an MBA intern specializing in digital marketing, you will have the opportunity to get hands-on experience and exposure to various aspects of digital marketing.',
    
    heading: 'Responsibilities:',
    p1:"● Assist in the development and execution of digital marketing strategies across various channels, including social media, email marketing, content marketing, SEO, and PPC.",
    p2:"● Conduct market research and analysis to identify target audience segments, competitor insights, and industry trends.",
    p3:"● Collaborate with the marketing team to create compelling content for digital platforms, ensuring consistency in brand messaging and voice.",
    p4:"● Monitor and analyze the performance of digital marketing campaigns using analytics tools, and provide insights and recommendations for optimization.",
    p5:"● Support the management of social media accounts, including content creation, scheduling, and engagement with followers.",
    p6:"● Assist in optimizing website content and user experience to improve search engine rankings and drive organic traffic.",
    p7:"● Contribute to the planning and execution of lead generation and conversion optimization initiatives.",
    p8:"● Stay up-to-date with the latest trends and advancements in digital marketing, sharing knowledge and insights with the team.",

    heading2: 'Requirements:',
    p11:"● Currently pursuing an MBA degree with a specialization in marketing or a related field.",
    p12:"● Strong passion for digital marketing and a solid understanding of its core principles and techniques.",
    p13:"● Knowledge of various digital marketing channels and platforms, including social media, email marketing, SEO, and PPC.",
    p14:"● Excellent analytical skills with the ability to interpret data and draw actionable insights.",
    p15:"● Strong written and verbal communication skills, with the ability to create engaging content.",
    p16:"● Proficiency in using digital marketing tools and platforms, such as Google Analytics, social media management tools, and content management systems.",
    p17:"● Self-motivated, detail-oriented, and able to manage multiple projects and meet deadlines.",
    p18:"● Ability to work effectively in a team environment, collaborating with cross-functional teams.",
    place:"Location: Lucknow",
}


export const JDobjTwo={
    jd: "PHP Developer - Moodle, Drupal, WordPress",
    head:'We are seeking a skilled and experienced PHP Developer with a focus on Moodle, Drupal, and WordPress to join our development team. The ideal candidate will have a strong background in customizing and developing solutions on these platforms, as well as expertise in PHP web development.',
    
    heading: 'Responsibilities:',
    p100:"● Drupal Development:",
    p101:"- Design and implement custom Drupal themes and modules based on project specifications.",
    p102:"- Collaborate with cross-functional teams to develop and maintain Drupal-based web applications.",
    p200:"● Moodle Development:",
    p201:"- Customize and extend Moodle functionalities to meet specific e-learning project requirements.",
    p202:"- Develop and integrate plugins, modules, and themes for Moodle-based learning management systems.",
    p300:"● Database Design and Optimization:",
    p301:"- Design and optimize database schemas for various web applications.",
    p302:"- Ensure data security and implement efficient database queries.",
    p400:"● Code Versioning and Documentation:",
    p401:"- Utilize version control systems (e.g., Git) for code management.",
    p402:"- Maintain clear and comprehensive documentation for all projects.",
    p500:"● Performance Optimization:",
    p501:"- Identify and address performance bottlenecks in applications.",
    p502:"- Conduct code reviews to ensure best practices and optimal performance.",
    p600:"● Collaboration and Communication:",
    p601:"- Collaborate with cross-functional teams, including designers and other developers.",
    p602:"- Communicate technical concepts to non-technical stakeholders.",
    
    heading2: 'Qualifications:',
    p11:"● BTech / MCA with 1-3 years of Exp.",
    p12:"● Proven experience as a PHP Developer with expertise in Moodle/Drupal, and WordPress.",
    p13:"● Strong understanding of PHP web development and MVC design patterns.",
    p14:"● Experience with front-end technologies such as HTML, CSS, JavaScript, and AJAX.",
    p15:"● Knowledge of web services and APIs integration.",
    p16:"● Familiarity with e-learning platforms and content management systems.",
    p17:"● Proficient in database design and optimization (MySQL, PostgreSQL, etc.).",
    p18:"● Experience with version control systems, preferably Git.",
    p19:"● Strong problem-solving skills and attention to detail.",

    // p21:"This is an internship position, offering an opportunity to gain practical experience and enhance your skills in Flutter app development. You will work closely with experienced developers and contribute to real-world projects. The internship duration and compensation will be determined based on mutual agreement.",
    // p22:"If you are passionate about mobile app development, have a strong foundation in Flutter, and are eager to learn and grow in a collaborative environment, we encourage you to apply.",
    place:"Location: Lucknow",
}
  
export const JDobjThree={
    jd: "Consultant - Digital Experience",
    head:'We are seeking a skilled Digital Experience Consultant with exposure to technical project management. This position requires a combination of expertise in digital experience design, user-centric approach, and hands-on experience in technical project management. The successful candidate will play a key role in shaping digital experiences while ensuring successful project delivery through effective project management practices.',
    
    heading: 'Key Responsibilities:',
    p100:"● Digital Experience Consulting:",
    p101:"- Collaborate with clients to understand their business objectives, target audience, and digital needs.",
    p102:"- Develop and implement comprehensive digital strategies aligned with business goals, leveraging technology to enhance user experiences.",
    p103:"- Conduct user research, persona development, and journey mapping to inform digital strategy recommendations.",
    p104:"- Lead the design process to create intuitive and user-friendly digital interfaces for websites, mobile apps, and other digital platforms.",
    p200:"● Technical Project Management:",
    p201:"- Assist in project planning, execution, and monitoring, ensuring projects are delivered on time, within scope, and within budget.",
    p202:"- Support CMS &amp; Web Application project teams in defining project objectives, deliverables, and success criteria.",
    p203:"- Coordinate cross-functional teams, including designers, developers, and content creators, to ensure alignment and collaboration.",
    p204:"- Monitor project progress, identify risks and issues, and support mitigation efforts to ensure project success.",
    p300:"● Client Engagement and Relationship Management:",
    p301:"- Serve as a point of contact for clients, maintaining strong relationships and communication throughout the project lifecycle.",
    p302:"- Understand client requirements, address concerns, and manage expectations to ensure client satisfaction.",
    p303:"- Provide strategic guidance and thought leadership to clients, helping them achieve their digital objectives.",
    p400:"● Technology Integration and Implementation Support:",
    p401:"- Assist in evaluating digital technologies, platforms, and tools to support digital initiatives.",
    p402:"- Support the implementation and integration of digital solutions, ensuring alignment with technical requirements.",
    p403:"- Collaborate with IT teams and developers to facilitate seamless integration and alignment with project objectives.",
    p500:"● Training Program Development:",
    p501:"- Collaborate with subject matter experts (SMEs) and training stakeholders to identify training needs and objectives.",
    p502:"- Design and develop technical training programs, courses, and materials based on identified needs and learning objectives.",
    p503:"- Incorporate instructional design principles, and best practices into training content development.",
    p504:"- Collect feedback from training participants, trainers, and stakeholders to evaluate training effectiveness and identify areas for improvement.",
    p505:"- Recommend and implement enhancements to training programs, content, and delivery methods based on evaluation results and industry best practices.",
    
    heading2: 'Requirements:',
    p11:"● MCA /B-Tech/MBA with 4+ years of experience in software development.",
    p12:"● At least 2+ years of experience working as a CMS Consultant or similar role, with a focus on content management systems and digital content strategies.",
    p13:"● Strong understanding of various CMS platforms, such as AEM, Drupal, etc.",
    p14:"● Experience in requirements gathering, system configuration, and user training for CMS implementations.",
    p15:"● Good understanding of web technologies.",
    p16:"● Excellent communication skills, with the ability to effectively collaborate with clients, stakeholders, and cross-functional teams.",
    p17:"● Strong problem-solving skills and attention to detail.",
    p18:"● Ability to prioritize tasks, meets deadlines, and manages multiple projects simultaneously.",
    
    place:"Location: Lucknow",
}
export const JDobjFour={
    jd: "UX/UI Junior Designer - CMS, Web & Mobile Applications",
    head:'We are seeking a talented UI/UI Junior Designer to join our team, focusing on designing intuitive and engaging user interfaces for CMS, web, and mobile applications. As a Junior Designer, you will work closely with cross-functional teams to create impactful digital experiences. This role offers an exciting opportunity to contribute to the entire design lifecycle, from concept ideation to implementation using tools like Figma, Canva and other similar design platforms.',
    
    heading: 'Responsibilities:',
    p1:"● Design: Create wireframes, prototypes, and high-fidelity visual designs for CMS, web, and mobile applications.",
    p2:"● Collaboration: Work with product managers, developers, and stakeholders to understand project requirements and user stories.",
    p3:"● User-Centered Design: Apply user-centered design principles to develop intuitive and functional interfaces.",
    p4:"● Simplicity: Translate complex concepts into simple and elegant user interfaces, ensuring consistency across all platforms.",
    p5:"● Usability Testing: Conduct usability testing and gather feedback to iterate and refine designs based on user insights.",
    p6:"● Design Systems: Develop and maintain design systems, style guides, and component libraries to ensure scalability and consistency.",
    p7:"● Industry Awareness: Stay updated on industry trends, emerging technologies, and best practices in UX/UI design and usability.",
    p8:"● Communication: Present and communicate design concepts and solutions effectively to team members and stakeholders.",

    heading2: 'Requirements:',
    p11:"● Education: Bachelor’s degree in Graphic Design, Interaction Design, or a related field.",
    p12:"● Experience: 1-2 years of experience in UX/UI design, with a portfolio showcasing your design process and projects for CMS, web, and mobile applications.",
    p13:"● Tools: Proficiency in design tools such as Figma, Adobe XD, Canva, Sketch, or similar platforms.",
    p14:"● Skills: Strong understanding of user-centered design principles, information architecture, and interaction design.",
    p15:"● Responsive Design: Experience with responsive design principles and designing for accessibility standards.",
    p16:"● Collaboration: Ability to work collaboratively in a fast-paced environment, prioritize tasks, and adapt to changing requirements.",
    p17:"● Communication: Excellent communication skills, both written and verbal, with the ability to articulate and defend design decisions.",
    p18:"● Attention to Detail: Keen attention to detail and a passion for creating visually appealing and functional user interfaces.",
    
    heading3: 'Preferred Qualifications:',
    p21:"● Front-End Development: Experience with front-end development technologies (HTML, CSS, JavaScript) is a plus.",
    p22:"● CMS Knowledge: Familiarity with content management systems (CMS) and their design implications.",
    p23:"● Agile/Scrum: Knowledge of Agile/Scrum development methodologies.",

    place:"Location: Lucknow",
}


export const JDobjFive={
    jd: "Quality Assurance Engineer",
    head:'We are looking for a detail-oriented and proactive Quality Assurance Engineer to join our growing team. As a QA Engineer, you will be responsible for ensuring the quality and reliability of our web applications, websites, and mobile applications through rigorous testing and quality assurance processes. You will collaborate closely with development teams to identify issues, validate fixes, and uphold the highest standards of product quality.',
    
    heading: 'Responsibilities:',
    p1:"● Review and analyze software requirements documentation.",
    p2:"● Design and execute comprehensive test plans and test cases.",
    p3:"● Conduct thorough manual testing of software functionalities.",
    p4:"● Identify, document, and report software defects following a defined bug reporting process.",
    p5:"● Collaborate effectively with developers to troubleshoot and resolve reported issues.",
    p6:"● Participate in code reviews to identify potential issues early in the development process.",
    p7:"● Stay up-to-date with the latest software testing methodologies and best practices.",

    heading2: 'Requirements:',
    p11:"● Minimum 1+ years of experience in manual software testing.",
    p12:"● Strong understanding of software development lifecycle (SDLC).",
    p13:"● Excellent analytical and problem-solving skills.",
    p14:"● Keen eye for detail and a passion for quality.",
    p15:"● Proficiency in using bug tracking tools.",
    p16:"● Experience with test case management tools (a plus).",
    p17:"● Strong communication skills, both written and verbal, with the ability to effectively communicate technical issues and solutions.",
    
    place:"Location: Lucknow",
}
  
export const JDobjSix={
    jd: "Digital Analyst",
    head:'We are seeking a skilled Digital Analyst to join our dynamic team. As a Digital Analyst, you will be responsible for leveraging data from various digital platforms to drive insights, optimize marketing strategies, and improve overall digital performance. Your primary focus will be on using Google Analytics and Power BI to extract, analyze, and visualize data that informs decision-making across the organization.',
    
    p1:"● Collect, analyze, and interpret data from various digital channels.",
    p2:"● Use tools such as Google Analytics and Google Tag Manager to monitor KPIs.",
    p3:"● Create and deliver regular reports and dashboards.",
    p4:"● Provide actionable insights and recommendations to improve digital marketing strategies.",
    p5:"● Analyze the performance of digital marketing campaigns and identify optimization opportunities.",
    p6:"● Conduct user behavior analysis to enhance user experience.",
    p7:"● Design, execute, and analyze A/B tests and experiments.",
    p8:"● Collaborate with cross-functional teams to ensure data-driven decision-making.",
    p9:"● Communicate findings and recommendations effectively to stakeholders.",

    
    heading2: 'Requirements:',
    p11:"● Bachelor’s degree in Marketing, Business Administration, Statistics, Computer Science, or a related field.",
    p12:"● Minimum 1+ years of proven experience as a Digital Analyst or in a similar role.",
    p13:"● Proficiency in digital analytics tools such as Google Analytics, Google Tag Manager, Adobe Analytics, etc.",
    p14:"● Strong analytical skills and experience with data visualization tools (e.g., Tableau, Power BI, Looker Studio).",
    p15:"● Knowledge of SEO, SEM, social media, and digital marketing best practices.",
    p16:"● Experience with A/B testing and experimentation methodologies.",
    p17:"● Excellent communication and presentation skills.",
    p18:"● Strong problem-solving abilities and attention to detail.",
    p19:"● Ability to work independently and as part of a team in a fast-paced environment.",
    p20:"● Certification in Google Analytics (e.g., Google Analytics Individual Qualification - GAIQ) or Power BI.",
    
    place:"Location: Lucknow",
}

export const JDobjSeven={
    jd: "Technical Project Manager (Software Development)",
    head:'We are seeking a highly motivated and experienced Technical Project Manager to join our dynamic team. The ideal candidate will have a strong background in managing Content Management System (CMS) and Web Application projects, with exposure to enterprise-level initiatives. As a Technical Project Manager, you will be responsible for overseeing the end-to-end delivery of digital projects, ensuring they are completed on time, within budget, and to the highest quality standards.',

    heading: 'Responsibilities:',
    p100:"● Technical Project Management:",
    p101:"- Assist in project planning, execution, and monitoring, ensuring projects are delivered on time, within scope, and within budget.",
    p102:"- Support CMS & Web Application project teams in defining project objectives, deliverables, and success criteria.",
    p103:"- Coordinate cross-functional teams, including designers, developers, and content creators, to ensure alignment and collaboration.",
    p104:"- Monitor project progress, identify risks and issues, and support mitigation efforts to ensure project success.",
    p105:"- Ensure adherence to project management best practices, methodologies, and quality standards.",
    p200:"● Client Engagement and Relationship Management:",
    p201:"- Serve as a point of contact for clients, maintaining strong relationships and communication throughout the project lifecycle.",
    p202:"- Understand client requirements, address concerns, and manage expectations to ensure client satisfaction.",
    p203:"- Provide strategic guidance and thought leadership to clients, helping them achieve their digital objectives.",
        
    heading2: 'Qualifications:',
    p11:"● MCA / B-Tech with 5+ years of experience in software development.",
    p12:"● At least 1+ years of experience working as a Project management / project coordinator or similar role, with a focus on web application projects.",
    p13:"● Strong understanding of various CMS platforms, such as AEM, Drupal, etc.",
    p14:"● Experience in requirements gathering, system configuration, and user training for CMS implementations.",
    p15:"● Proficiency in HTML, CSS, JavaScript, and other web technologies.",
    p16:"● Excellent communication skills, with the ability to effectively collaborate with clients, stakeholders, and cross-functional teams.",
    p17:"● Strong problem-solving skills and attention to detail.",
    p18:"● Ability to prioritize tasks, meet deadlines, and manage multiple projects simultaneously.",
    
    place:"Location: Lucknow",
}

// export const JDobjTwo={
//     jd: "B.Tech / MCA Intern with Flutter Experience",
//     head:'We are seeking a talented and enthusiastic B.Tech / MCA intern with experience in Flutter to join our development team. As an intern with Flutter experience, you will have the opportunity to work on exciting mobile application projects and gain valuable hands-on experience in developing cross-platform apps.',
    
//     heading: 'Responsibilities:',
//     p1:"1. Collaborate with the development team to design and develop mobile applications using Flutter framework.",
//     p2:"2. Write clean, efficient, and maintainable code while adhering to coding standards and best practices.",
//     p3:"3. Participate in the entire software development lifecycle, including requirements gathering, design, development, testing, and deployment.",
//     p4:"4. Troubleshoot and debug issues in the application, and propose solutions to improve performance and user experience.",
//     p5:"5. Work closely with UI/UX designers to implement intuitive and visually appealing user interfaces.",
//     p6:"6. Assist in testing and quality assurance activities to ensure the delivery of high-quality applications.",
    
//     heading2: 'Requirements:',
//     p11:"1. Pursuing or recently completed a B.Tech / MCA degree in Computer Science, Information Technology, or a related field.",
//     p12:"2. Strong knowledge and hands-on experience in Flutter framework.",
//     p13:"3. Familiarity with Dart programming language.",
//     p14:"4. Basic understanding of mobile app development concepts, UI/UX principles, and RESTful APIs.",
//     p15:"5. Good problem-solving and analytical skills.",
//     p16:"6. Ability to work independently and in a team environment.",
//     p17:"7. Excellent communication and interpersonal skills.",
//     p18:"8. Prior experience with Git version control is a plus.",

//     p21:"This is an internship position, offering an opportunity to gain practical experience and enhance your skills in Flutter app development. You will work closely with experienced developers and contribute to real-world projects. The internship duration and compensation will be determined based on mutual agreement.",
//     // p22:"If you are passionate about mobile app development, have a strong foundation in Flutter, and are eager to learn and grow in a collaborative environment, we encourage you to apply.",
//     place:"Location: Lucknow",
// }

// export const JDobjThree={
//     jd: "Business Development Executive",
//     head:'We are seeking a highly motivated and results-driven Business Development Executive to join our growing team. As a Business Development Executive, you will be responsible for identifying new business opportunities, building and maintaining relationships with clients, and driving revenue growth for our organization. You will play a key role in expanding our customer base and achieving sales targets.',
    
//     heading: 'Responsibilities:',
//     p1:"1. Identify and research potential clients and market segments to target for business growth.",
//     p2:"2. Generate leads through various channels such as cold calling, networking, and referrals.",
//     p3:"3. Conduct sales presentations and product demonstrations to prospective clients.",
//     p4:"4. Understand clients' business needs and objectives and tailor solutions to meet their requirements.",
//     p5:"5. Develop and maintain strong relationships with existing clients, ensuring their satisfaction and identifying upselling opportunities.",
//     p6:"6. Collaborate with the marketing team to develop effective strategies to promote our products and services.",
//     p7:"7. Stay updated on industry trends and competitor activities to identify opportunities for differentiation.",
//     p8:"8. Prepare sales proposals and negotiate contracts with clients.",
//     p9:"9. Track and report on sales activities and performance metrics.",
    
//     heading2: 'Requirements:',
//     p11:"1. MBA - Marketing.",
//     p12:"2. Proven experience in business development, sales, or a similar role.",
//     p13:"3. Excellent communication and negotiation skills.",
//     p14:"4. Strong presentation and interpersonal skills.",
//     p15:"5. Ability to build and maintain relationships with clients.",
//     p16:"6. Self-motivated with a results-oriented mind-set.",
//     p17:"7. Familiarity with CRM software and sales tools.",
//     p18:"8. Knowledge of the industry and market trends.",
//     p19:"9. Ability to work independently as well as part of a team.",
//     // place:"Location: Lucknow",
// }
