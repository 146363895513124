import { Link } from "react-router-dom";
import styled from "styled-components";
export const LinkButton=styled(Link)`
border-radius: 5px;
background:${({primary})=>(primary?' #fff':'#fff')};
white-space: nowrap;
padding: ${({big})=>(big? '12px 40px' : '12px 20px')};
color: ${({dark})=>(dark?'#010606':'#0a3174')};
font-weight: ${({fontBig})=>(fontBig?'510':'510')};
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;
text-decoration:none;
&:hover{
transition: all 0.2s ease-in-out;
background: ${({primary})=>(primary? '#fff': ' #fff')};
}`