import "./about.css";
import second from "../../../images/aboutHome.jpg"
const Layout = () => {
  return (

  <div className="about-wrapper">
      <div className="bg-gray body">
      <div className="wrapper mt-5 mt-md-0">
          <img className="lay mt-md-5" src={second} alt="pic"/>
          <div className="text-box">
      <div className="s-divi text-center"><span>About Us</span></div>
            <h2 className="py-2">Helping organizations enhance their digital experience</h2>
            <p>In the digital arena, we strive to comprehend the challenges and obstacles faced by organizations.</p>
            <p>By understanding their unique needs and requirements, we offer multiple solutions that align with their budget and future objectives.</p>
            <p>Our focus lies in enhancing user experiences at every interaction point, leveraging cutting-edge technologies to deliver a seamless and exceptional journey.</p>
          </div>
        </div>
      </div>
    </div>
  // <div className="about-wrapper">
  //     <div className="bg-gray body">
  //     <div className="wrapper pt-5 mt-5 pt-md-0 mt-md-0">
  //         <img className="lay mt-md-5" src={second} alt="pic"/>
  //         <div className="text-box">
  //     <div className="s-divi text-center"><span>About Us</span></div>
  //           <h2 className="py-2">Helping organizations enhance their digital experience</h2>
  //           <p>In the field of digital marketing, we strive to comprehend the challenges and obstacles faced by organizations.</p>
  //           <p>By understanding their unique needs and requirements, we offer multiple solutions that align with their budget and future objectives.</p>
  //           <p>Our focus lies in enhancing user experiences at every interaction point, leveraging cutting-edge technologies to deliver a seamless and exceptional journey.</p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  );
};
export default Layout;
