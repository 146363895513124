
export const careerObjOne={
  id:'career',
  lightBg: true,
  // heading: 'About Founder and CEO',

  lightText: false,
  lightTextDesc: false,
  // topLine: 'Digital Transformation',
  headline: 'Nurturing & Empowering Talent for Digital Technologies',

  p1:"We empower individuals with the necessary resources and unwavering support, fostering an environment where they can take ownership of their work and ideas. Additionally, we instill a culture of continuous learning, encouraging them to adapt and stay abreast of emerging trends and technologies.",
  // p1:"At Afucent, we are committed to nurturing young talent and polishing old ones.",
  // p2:"Our diverse team includes people at different stages of our careers. ",
  
  // buttonLabel: 'learn more',
  imgStart: false,
  img: require('../../../images/career.jpg'),
  alt:'Team',
  dark: false,
  primary: false,
  darkText: true
}