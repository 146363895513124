import React, { useState, useEffect } from "react";
import {InfoContainer,InfoWrapper,InfoRow,Column1,Column2, TextWrapper,TopLine,Heading,Subtitle, ImgWrap,Img} from "./InfoElements";

const InfoSection = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, p1, p2, p3, p4, buttonLabel, buttonLink, img, img2, alt, primary, dark, dark2 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <>
      <InfoContainer  lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{p1}</Subtitle>
                <Subtitle darkText={darkText}>{p2}</Subtitle>
                <Subtitle darkText={darkText}>{p3}</Subtitle>
                {/* <a href="https://www.linkedin.com/company/afucent-technologies/">
                  <i className="fa-brands fa-linkedin text-info"></i>
                </a> */}
                
              </TextWrapper>
            </Column1>
            <Column2>
            <ImgWrap>
              {isMobile ? (
                <Img src={img} alt={alt} /> // Replace `mobileImg` with the path to the mobile version of the image
              ) : (
                <Img src={img2} alt={alt} />
              )}
            </ImgWrap>
          </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
       
    </>
  );
};

export default InfoSection;
