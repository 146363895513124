// import {ServicesH1} from "../components/Expertise/Main/ServiceElements"
import "./contact.css";
import emailjs from '@emailjs/browser'; 
import React, {useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import ReCAPTCHA from "react-google-recaptcha";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import contactImg from "../images/contactBanner.png";
import contactImg2 from "../images/contactBanner.png";
import Banner from "../components/SectionBanner/Banner";

const Contact = () => {
  const [verified, setVerified]= useState(false); 
  const [isOpen, setIsOpen] = useState(false);
  const [submitForm, setSubmitForm] = useState("none");
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }
   const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_3sc641m', 'template_c3gnckf', e.target, 'UnSYDxc_GSZO_jcM5')
      .then((result) => {
          setSubmitForm("success")
          setTimeout(() => {setSubmitForm('none')}, 10000);
          console.log(result.text);
        }, (error) => {
          setSubmitForm("error")
          setTimeout(() => {setSubmitForm('none')}, 10000);
          console.log(error.text);
        });
      e.target.reset();
  };
  const captcha =useRef(null);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = (e) => {
    sendEmail(e);
    captcha.current.reset();
    setVerified(false); // Reset the verification status to false after form submission
  
    e.preventDefault();
  };
  
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.737391963776!2d80.94543321423654!3d26.848303669325077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd08a44d23df%3A0x942d741751b38cca!2sNew%20Janpath%20Complex%2C%20Ashok%20Marg%2C%20Sadullah%20Nagar%2C%20Narhi%2C%20Hazratganj%2C%20Lucknow%2C%20Uttar%20Pradesh%20226001!5e0!3m2!1sen!2sin!4v1659702526654!5m2!1sen!2sin'
  
  return (
    <div className="bg-gray contact-bg"> 
    <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Banner cName="banner-mid" bannerImg={contactImg}  bannerImg2={contactImg2} title="Ready to Elevate Your Business? Start a Conversation with Us" btnClass="hide" />

      <div className="comp-padding bg-light">
        <div className="container-fluid p-4 pt-5 pt-md-0 pb-md-4">
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 main-text pb-4 pb-md-5">
              We are your ideal partner in navigating business complexities, providing expertise and insights throughout your journey.
            </div>
          </div>
        </div>
      </div>


      <h1 className="contact-head text-center pt-5">Get In Touch</h1>
      <section className='container pb-5 mb-5 contacts'>
       
        <div className='row'>
          <div className='col-lg-6'>
            <iframe title="Advertisement" src={map}></iframe>
          </div>
          <div className='col-lg-6 mt-5 mt-lg-0'>

            <div className="row">
              <div className="col-12">
                <h5>Contact Us</h5>
                <p>We're open for any suggestion or just to have a chat</p>
              </div>
              <div className="col-12">
                <h5>Address:</h5>
                <p>4th Floor, New Janpath Complex 9 Ashok Marg, Hazratganj, Lucknow 226001</p>
              </div>
              <div className="col-sm-6">
                <h5>Email:</h5>
                <p> hr@afucent.com</p>
              </div>
              <div className="col-sm-6">
                <h5>Phone:</h5>
                <p> +91-9321800758</p>
              </div>
            </div>

            <form action='' onSubmit={handleSubmit}  >
              <div className='flexSB'>
                <input className="rounded" type='text' name="fullName" placeholder='Name' required/>
                <input className="rounded" type='email'name="email" placeholder='Email' required/>
              </div>
              <input className="rounded" type='text' name="subject" placeholder='Subject' required/>
              <textarea className="rounded" name="message" cols='30' rows='5' placeholder="Create a message here..." required></textarea>
              
              {submitForm==="success" && <div className="text-success mb-3">Thanks for submitting</div>}
              {submitForm==="error" && <div className="text-danger mb-3">Something went wrong</div>}
            
              <ReCAPTCHA ref={captcha} sitekey="6LcBU0kkAAAAAGYVcUP6sokXvLYNsOIaH4wjexS9"onChange={onChange}/>
              <button type='submit'  className={verified?'active-btn':'disable-btn'} disabled={!verified} >SEND MESSAGE</button>
            </form>
           
          </div>
        </div>
      </section>
      <Footer/>
    </div>

  )
}

export default Contact