import React, { useState } from "react";
import './main.css'
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Banner from "../components/SectionBanner/Banner";
import expertiseImg from "../images/expertiseBanner.png";
import expertiseImg2 from "../images/expertiseBanner2.png";
import DigitalExperience from "../components/Expertise/DigitalExperience";
import MarketingTechnologies from "../components/Expertise/MarketingTechnologies";
import DigitalTransformation from "../components/Expertise/DigitalTransformation";
import InfoSection from "../components/Expertise/InfoSection";
import { expertiseObjOne, expertiseObjTwo, expertiseObjThree } from "../components/Expertise/InfoSection/Data";


// mobile navbar toggle function 
function Expertise() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-gray">
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Banner
        cName="banner-mid"
        bannerImg={expertiseImg}
        bannerImg2={expertiseImg2}
        title="Optimizing Customer Experience through Holistic Technology"
        btnClass="hide"
      />

      <div className="comp-padding">
        <div className="container-fluid pt-5 pt-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 main-text">
              With versatile expertise across industries, we deliver tailored services to meet your unique needs.
            </div>
          </div>
        </div>
        <InfoSection  {...expertiseObjOne}/> 
        <DigitalExperience/>
        <InfoSection {...expertiseObjTwo}/>
        <MarketingTechnologies/>
        <InfoSection {...expertiseObjThree}/>
        <DigitalTransformation/>
      </div>

      <Footer />
    </div>
  );
}

export default Expertise;


