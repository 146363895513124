
// import Fifth from "../../../images/nurture.png";
// import Banner2 from "../../../images/Banner2.jpeg";
// import one from "../../../images/five.jpg";

import desk1 from "../../../images/homeBanner.png";
// import desk2 from "../../../images/desk2.jpg";
// import desk3 from "../../../images/desk3.jpg";

import mbl1 from "../../../images/homeBanner2.png";
// import mbl2 from "../../../images/mbl2.jpg";
// import mbl3  from "../../../images/mbl3.jpg";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
 
  {title: "Crafting Differentiated Digital Experience through Technology",
  button: "Contact Us",
    url: mbl1,
    urls: desk1
  }, 
 


  // {
  //   title: "Transforming Business through Advanced Technologies",
  //   url: mbl2,
  //   urls: desk2
  // },
  // {
  //   title: "Unlock Your Business's Potential with Cutting-Edge Marketing Technologies",
  //   url: mbl3,
  //   urls: desk3,
  // },
];