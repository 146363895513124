import { Link } from "react-router-dom";
import styled from "styled-components";
export const LinkButton=styled(Link)`
border-radius: 50px;
width: 100%;
background:${({primary})=>(primary?' #fff':'#fff')};
white-space: nowrap;
padding: ${({big})=>(big? '12px 48px' : '10px 30px')};
color: ${({dark})=>(dark?'#010606':'#0a3174')};
font-size: ${({fontBig})=>(fontBig?'20px':'16px')};
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
outline: none;
border: none;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.2s ease-in-out;
text-decoration:none;
&:hover{
transition: all 0.2s ease-in-out;
background: ${({primary})=>(primary? '#fff': ' #0a3174')};
color: ${({primary})=>(primary? '#0a3174': ' #fff')};
}`