import React, {useEffect, useState}from 'react'
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import Icon3 from '../../images/AfucentLogo.png';
import { NavLink } from 'react-router-dom';

import './nav.css';
// import Dropdown from './Dropdown';

import { Nav,NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItems, NavHome} from './NavBarElement';
import { animateScroll as scroll } from 'react-scroll';

const NavBar = ({toggle}) => {
    const [scrollNav, setScrollNav]= useState(false);
    const changeNav=()=>{
        if(window.scrollY>= 80 )
        {setScrollNav(true); }
        else
        setScrollNav(false);
    }
    useEffect(()=>{
        window.addEventListener('scroll',changeNav)},[])
   const toggleHome=()=>{
    scroll.scrollToTop();
   }


  return (
    <> 
    <IconContext.Provider value={{color: '#052257'}}>
    <Nav scrollNav={scrollNav}>
        <NavbarContainer>
            <NavHome to='/' onClick={toggleHome}  exact='true' >
            <NavLogo src={Icon3} to='/' onClick={toggleHome}/>
            </NavHome>
            <MobileIcon onClick={toggle}>
                <FaBars/>
            </MobileIcon >
            <NavMenu> 
                <NavItems>
                <NavLink className='nav-text' to='/' onClick={toggleHome} exact={true} activeClassName='active'>Home</NavLink>
               {/* <NavLink className='nav-text' to='/' onClick={toggleHome}  exact='true' >Home </NavLink> */}
                </NavItems>
                <NavItems>
               <NavLink className='nav-text' to='/service' onClick={toggleHome}  exact='true' >Services </NavLink>
                </NavItems>
                <NavItems>
               <NavLink className='nav-text' to='/expertise' onClick={toggleHome}  exact='true' >Expertise </NavLink>
                </NavItems >
                <NavItems>
               <NavLink className='nav-text' to='/about' onClick={toggleHome}  exact='true' >About Us </NavLink>
                </NavItems>
                <NavItems>
               <NavLink className='nav-text' to='/careers' onClick={toggleHome}  exact='true' >Careers </NavLink>
                </NavItems>
                <NavItems>
                <NavLink className='nav-text' to='/contact' onClick={toggleHome}  exact='true' >
                  <div className='btn px-4 py-2' style={{background:"#0a3174", color:"#ffffff"}}>Contact Us</div>
                </NavLink>
                </NavItems>
            </NavMenu>
        </NavbarContainer>
    </Nav>  
    </IconContext.Provider>
     </>
  )
}

export default NavBar