import React from "react";
import './services.css';
// import { Button } from "../ButtonElements";

// eslint-disable-next-line
import {Head, InfoContainer,InfoWrapper,InfoRow,Column1,Column2, TextWrapper,TopLine,Heading,Subtitle,BtnWrap, ImgWrap,Img, NavLinks} from "./InfoElements";

// eslint-disable-next-line
const InfoSection = ({heading, lightBg, id, imgStart, topLine, lightText, headline, darkText, description,p1,p2,p3,p4, buttonLabel, buttonLink, img,alt, primary, dark, dark2}) => {
  return (
    <>
      <InfoContainer  lightBg={lightBg} id={id}>
        <InfoWrapper>
          {/* <Head className="text-dark">{heading}</Head> */}
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{p1}</Subtitle>
                <Subtitle darkText={darkText}>{p2}</Subtitle>
                <Subtitle darkText={darkText}>{p3}</Subtitle>
                <Subtitle darkText={darkText}>{p4}</Subtitle>
                
                {/* <BtnWrap>
                  <Button to="home" smooth={true} duration={500} spy={true} exact='true' className="link"
                  offset={-80} primary={primary?1:0} dark={dark?1:0} dark2={dark2?1:0}>
                    <NavLinks to={buttonLink}>
                      {buttonLabel}
                    </NavLinks>
                  </Button>
                </BtnWrap> */}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
       
    </>
  );
};

export default InfoSection;
