import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom';

export const NavLinks=styled(LinkR)`
 color: #fff;
 align-items: center;
 text-decoration: none;
 cursor: pointer;
 &.active{
    border-bottom: 3px solid #0a3174;
 }`; 


export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? "#f9f9f9" : "#163057")};
  display: flex;
  margin-top: -20px;
  margin-bottom: -20px;
  @media screen and (max-width: 768px) {
    padding-bottom: 150px;
}
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 650px;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 20px;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    height: 550px;
    padding: 60px 20px;
  }
`;


export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas:
    ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
     
     
  }
 
`;

export const Column1 = styled.div` 
  margin-bottom: 0px;
  padding: 0 10px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 0px;
  padding: 0 9px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width:900px;
  padding-top: 0;
  padding-bottom: 0px;
`;

export const TopLine = styled.p`
color: ${({ lightText }) => (lightText ? "#f7f8fa" : "black")};
  font-size: 16px;
  color: #00bb85;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Head = styled.h1`
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  
  @media screen and (max-width: 480px) {
    margin-top: 30px;
    font-size: 1.6rem;
  }
`;


export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  max-width: 665px;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  height: 500px;
  margin: 0 0 0px 0;
  border-radius:10px;
  
  @media screen and (max-width: 480px) {
    height: 250px;
    font-size: 32px;
  }
`;
