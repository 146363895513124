import React from "react";
import "./CareerStyles.css";
// import { LinkButton } from "../LinkButton";

const JD = ({jd, head, place,
  heading, p1, p2, p3, p4, p5, p6, p7, p8, p9, 
  heading2, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20,
  heading3, p21, p22, p23, p24, p25, p26, p27, p28, p29,
  p100, p101, p102, p103, p104, p105,  
  p200, p201, p202, p203, p204, p205,  
  p300, p301, p302, p303, p304, p305,  
  p400, p401, p402, p403, p404, p405,  
  p500, p501, p502, p503, p504, p505,  
  p600, p601, p602, p603, p604, p605,  
  p700, p701, p702, p703, p704, p705,  
  p800, p801, p802, p803, p804, p805,  
  p900, p901, p902, p903, p904, p905,  
}) => {
  return (
    <div className="jd">
        <div>{jd}</div>
        <p>{head}</p>

        <div>{heading}</div>
        
        <p>{p1}</p>

        <b>{p100}</b>
        <p></p>
        <p>{p101}</p>
        <p>{p102}</p>
        <p>{p103}</p>
        <p>{p104}</p>
        <p>{p105}</p>

        <p>{p2}</p>
        
        <b>{p200}</b>
        <p></p>
        <p>{p201}</p>
        <p>{p202}</p>
        <p>{p203}</p>
        <p>{p204}</p>
        <p>{p205}</p>
        
        <p>{p3}</p>
        
        <b>{p300}</b>
        <p></p>
        <p>{p301}</p>
        <p>{p302}</p>
        <p>{p303}</p>
        <p>{p304}</p>
        <p>{p305}</p>
        
        <p>{p4}</p>
        
        <b>{p400}</b>
        <p></p>
        <p>{p401}</p>
        <p>{p402}</p>
        <p>{p403}</p>
        <p>{p404}</p>
        <p>{p405}</p>
        
        <p>{p5}</p>
        
        <b>{p500}</b>
        <p></p>
        <p>{p501}</p>
        <p>{p502}</p>
        <p>{p503}</p>
        <p>{p504}</p>
        <p>{p505}</p>
        
        <p>{p6}</p>
        
        <b>{p600}</b>
        <p></p>
        <p>{p601}</p>
        <p>{p602}</p>
        <p>{p603}</p>
        <p>{p604}</p>
        <p>{p605}</p>
        
        <p>{p7}</p>
        
        <b>{p700}</b>
        <p></p>
        <p>{p701}</p>
        <p>{p702}</p>
        <p>{p703}</p>
        <p>{p704}</p>
        <p>{p705}</p>
        
        <p>{p8}</p>
        
        <b>{p800}</b>
        <p></p>
        <p>{p801}</p>
        <p>{p802}</p>
        <p>{p803}</p>
        <p>{p804}</p>
        <p>{p805}</p>
        
        <p>{p9}</p>
        
        <b>{p900}</b>
        <p></p>
        <p>{p901}</p>
        <p>{p902}</p>
        <p>{p903}</p>
        <p>{p904}</p>
        <p>{p905}</p>

        <div>{heading2}</div>
        <p>{p11}</p>
        <p>{p12}</p>
        <p>{p13}</p>
        <p>{p14}</p>
        <p>{p15}</p>
        <p>{p16}</p>
        <p>{p17}</p>
        <p>{p18}</p>
        <p>{p19}</p>
        <p>{p20}</p>

        <div>{heading3}</div>
        <p>{p21}</p>
        <p>{p22}</p>
        <p>{p23}</p>
        <p>{p24}</p>
        <p>{p25}</p>
        <p>{p26}</p>
        <p>{p27}</p>
        <p>{p28}</p>
        <p>{p29}</p>

        <div>{place}</div>

        <p>
          Join us and contribute to exciting projects in a collaborative environment. Interested candidate can send resume at:<br/> 
          <a className="career-mail" href="mailto:career@afucent.com"><b>career@afucent.com</b></a>
        </p>
        {/* <LinkButton to="/contact" className="col-6 col-md-2">Apply</LinkButton> */}
    </div>
  );
};

export default JD;
