import React, { useEffect, useRef } from "react";
import './Clients.css'
import client1 from "../../../images/rs.png";
import client2 from "../../../images/va.png";
import client3 from "../../../images/az.png";
import client4 from "../../../images/vs.png";
import client5 from "../../../images/fb.png";
import client6 from "../../../images/ls.png";
import client7 from "../../../images/rana.png";
import client8 from "../../../images/vm.png";
import client9 from "../../../images/et.png";

const Clients = () => {

    const arr = [client1,client2,client3,client4,client5,client6,client7,client8,client9]

    const sliderRef = useRef(null);

    useEffect(() => {
      const clone = sliderRef.current.cloneNode(true);
      sliderRef.current.parentNode.appendChild(clone);
    }, []);
      
    return (
        <>
    <section className='sm client-bg'>
        <div className='sh-heading mb-4'>
        <span>
        Clients & Partners
        </span>
          {/* <h2 className='text-light'>Clients & Partners We Have Worked With</h2> */}
        </div>

        <div className="marquee light logos">
          <div className="logos-slide" ref={sliderRef}>
            {arr.map((i)=>(<img src={i} alt="" class="client-logo" />))}
          </div>
        </div>

        {/* <div class="marquee light">
            <div class="client-carousel">
            {arr.map((i)=>(<img src={i} alt="" class="client-logo" />))}
            {arr.map((i)=>(<img src={i} alt="" class="client-logo" />))}
            {arr.map((i)=>(<img src={i} alt="" class="client-logo" />))}
            </div>
        </div> */}

    </section>
    </>
  )
}

export default Clients