import React, { useState } from "react";
import './main.css'
import Sidebar from "../components/Sidebar";
import NavBar from "../components/NavBar"; 
import Footer from "../components/Footer";
import Banner from "../components/SectionBanner/Banner";
import serviceImg from "../images/serviceBanner.png";
import serviceImg2 from "../images/serviceBanner2.png";
import InfoSection from "../components/Service/InfoSection";
import { serviceObjOne, serviceObjTwo, serviceObjThree } from "../components/Service/InfoSection/Data";

function Service() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-gray">
    <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <Banner cName="banner-mid" bannerImg={serviceImg} bannerImg2={serviceImg2} title="A Methodological Approach to Delivering Exceptional Results" btnClass="hide" />

      <div className="comp-padding">
        <div className="container-fluid pt-5 pt-md-0">
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 main-text pb-md-5">
              We provide end-to-end support for your projects, offering comprehensive solutions in Digital Experience, Digital Transformation, and Marketing Technologies.
            </div>
          </div>
        </div>
        <InfoSection  {...serviceObjOne}/> 
        <InfoSection {...serviceObjTwo}/>
        <InfoSection {...serviceObjThree}/>
      </div>
      <Footer/>
    </div>
  )
}

  export default Service;