import { SidebarContainer, CloseIcon, Icon, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'
import { animateScroll as scroll } from 'react-scroll';


const Sidebar  = ({isOpen, toggle}) => {
    
    const toggleHome=()=>{
        scroll.scrollToTop();
       }

  return (
   <SidebarContainer isOpen={isOpen} onClick={toggle}>
    <Icon onClick={toggle}>
        <CloseIcon/>
    </Icon>
    <SidebarWrapper>
        <SidebarMenu>
            <SidebarLink to='/' onClick={toggleHome} exact='true' >Home</SidebarLink>
            <SidebarLink to='/about' onClick={toggleHome} exact='true' >About</SidebarLink>
            <SidebarLink to='/expertise' onClick={toggleHome} exact='true' >Expertise</SidebarLink>
            <SidebarLink to='/service' onClick={toggleHome} exact='true' >Services</SidebarLink>
            <SidebarLink to='/careers' onClick={toggleHome} exact='true' >Career</SidebarLink>
            <SidebarLink to='/contact' onClick={toggleHome} exact='true' >Contact Us</SidebarLink>
        </SidebarMenu>
    </SidebarWrapper>
   </SidebarContainer>
  )
}

export default Sidebar 