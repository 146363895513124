const ExpertiseDetails=[
    {
        id:"1",
        Image: require('../../../images/expertise1.jpeg'),
        name: "Digital Experience",
        // name: "Digital Experience Management Practice",
   
    },
    {
        id:"2",
        Image: require('../../../images/expertise2.jpeg'),
        name: "Marketing Technologies",
       
    },
    {
        id:"3",
        Image: require('../../../images/expertise3.jpeg'),
        name: "Digital Transformation",
   
    },
]
export default ExpertiseDetails;

