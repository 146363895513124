import { animateScroll as scroll } from 'react-scroll';


import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroBtnWrapper} from '../HeroSection/HeroElements'
import { LinkButton } from '../../LinkButton';
const Connect = () => {
    // const [hover, setHover]=useState(false);
    // const onHover=()=>{
    //     setHover(!hover);
    // }
    const toggleHome=()=>{
        scroll.scrollToTop();
       }

  return (
    <HeroContainer id='home'> 
        <HeroBg>
            <VideoBg alt='team' src={require("../../../images/Connect.jpg")} />
        </HeroBg>
        <HeroContent>
            
            <HeroH1>Let's help you Elevate Your Business</HeroH1>
        
            <HeroBtnWrapper>
                <LinkButton to='/contact' onClick={toggleHome}  exact='true' >Learn more</LinkButton>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default Connect