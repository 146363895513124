import React from 'react'
import "./aboutSection.css";
import { AiOutlineCloudSync } from 'react-icons/ai';
import { IoIosGitBranch } from 'react-icons/io';
import { BsBarChart, BsCodeSlash } from 'react-icons/bs';
import { MdOutlineDesignServices } from 'react-icons/md';
import { SlGraph } from 'react-icons/sl';

const Values = () => {
  return (
    <>
      <div className="container-fluid text-center">
        <div className="row text-light team-bg">
          <div className="value-font text-center pb-4">Driven by a Passionate Team</div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <BsCodeSlash className="icon mb-3" />
            <h5 className="icon-head">Full Stack Developers</h5>
          </div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <BsBarChart className="icon mb-3" />
            <h5 className="icon-head">Senior Business Analyst</h5>
          </div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <IoIosGitBranch className="icon mb-3" />
            <h5 className="icon-head">AEM & Magento Developers</h5>
          </div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <MdOutlineDesignServices className="icon mb-3" />
            <h5 className="icon-head">UX/UI Specialist</h5>
          </div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <SlGraph className="icon mb-3" />
            <h5 className="icon-head">Digital Analyst</h5>
          </div>
          <div className="value-card col-md-6 col-lg-4 p-4">
            <AiOutlineCloudSync className="icon mb-3" />
            <h5 className="icon-head">DevOps Engineers</h5>
          </div>
        </div>
      </div>
    </>
  )
}

export default Values
