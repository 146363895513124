import React from 'react'
import "./aboutSection.css";
import { FiUsers } from 'react-icons/fi';
import { FaRegLightbulb } from 'react-icons/fa';
import { MdQueryStats } from 'react-icons/md';
import { AiOutlineThunderbolt } from 'react-icons/ai';

const Values = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row text-light service-bg">
          <div className="value-font text-center pb-4">Our Core Values</div>
          <div className="value-card col-md-6 col-lg-3 p-4">
            <FiUsers className="icon mb-3" />
            <h5 className="icon-head">Customer Focused</h5>
            <div>Customer satisfaction is our top priority, we  emphasize in understanding & fulfilling their need and work for having long term association with them.</div>
          </div>
          <div className="value-card col-md-6 col-lg-3 p-4">
            <FaRegLightbulb className="icon mb-3" />
            <h5 className="icon-head">Solution Oriented</h5>
            <div>We offer a unique and tailored approach to meet the diverse needs of our customers.</div>
          </div>
          <div className="value-card col-md-6 col-lg-3 p-4">
            <MdQueryStats className="icon mb-3" />
            <h5 className="icon-head">Passionate</h5>
            <div>We possess a deep passion for embracing and harnessing the potential of emerging technologies.</div>
          </div>
          <div className="value-card col-md-6 col-lg-3 p-4">
            <AiOutlineThunderbolt className="icon mb-3" />
            <h5 className="icon-head">Agile</h5>
            <div>We adhere to an agile model, enabling us to deliver in short cycles and promptly take corrective action when necessary.</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Values
